<template>
  <div>
    <my-header></my-header>
    <div class="box">
      <div>
        <span>供求信息发布</span>
        <span @click="looksetbusiness" class="looksetbusiness"
          >查看自己已提交业务</span
        >
        <!-- <span @click="looksetbusinessall" class="looksetbusinessall"
          >查看全部已提交业务</span
        > -->
      </div>
      <div class="box-form">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="180px"
          
          class="demo-ruleForm"
        >
          <el-form-item label="标题" prop="title">
            <el-input v-model="ruleForm.title"></el-input>
          </el-form-item>
          <el-form-item label="地区" prop="region">
           <div> 
            <div v-if='Not' > 
            <p class="position">{{this.position}}</p>
            <p class="qiehuan">定位不准？ <el-button type="text"  @click="qiehuan()">点击此处</el-button>手动选择地址</p>
           
              </div>
           <div v-else >
            <el-cascader 
            class="address"
              ref="cascaderAddr"
              placeholder="选择地址"
              v-model="ruleForm.region"
              :options="options"
              @change="regionhandleChange"
            ></el-cascader>
            </div>
            </div>
         
          </el-form-item>
          <el-form-item label="类型" prop="purchaseType">
            <el-radio-group v-model="ruleForm.purchaseType">
              <el-radio label="1">供应商</el-radio>
              <el-radio label="2">采购商</el-radio>
              
            </el-radio-group>
          </el-form-item>

          <el-form-item label="单位" prop="purchasingUnit">
            <el-input v-model="ruleForm.purchasingUnit"></el-input>
          </el-form-item>
          <el-form-item label="项目联系人" prop="projectContact">
            <el-input v-model="ruleForm.projectContact"></el-input>
          </el-form-item>
          <el-form-item label="项目联系人电话" prop="projectContactPhone">
            <el-input v-model="ruleForm.projectContactPhone"></el-input>
          </el-form-item>
          <el-form-item label="预计最佳开始跟踪时间" required>
            <el-date-picker
              v-model="ruleForm.startTrackingTime"
              type="date"
              placeholder="选择日期时间"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptionsStart"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="预计需求时间" required>
            <el-date-picker
              v-model="ruleForm.demandTime"
              type="date"
              placeholder="选择日期时间"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptionsEnd"
            >
            </el-date-picker>
          </el-form-item>
          <!-- <el-form-item label="业务类型" prop="type">
            <el-radio-group v-model="ruleForm.type">
              <el-radio label="0">单人</el-radio>
              <el-radio label="1">多人</el-radio>
            </el-radio-group>
          </el-form-item> -->
          <el-form-item label="预算" prop="budget">
            <el-input v-model="ruleForm.budget"></el-input>
          </el-form-item>
          <el-form-item label="业务所在地址" prop="address" >
            <el-input v-model="ruleForm.address" placeholder="省/市/县/区(乡)/街道/门牌号"></el-input>
          </el-form-item>
          <el-form-item label="业务内容" prop="businessContent">
            <el-input
              type="textarea"
              v-model="ruleForm.businessContent"
            ></el-input>
          </el-form-item>
          <el-form-item label="备注附件">
            <el-upload
              class="upload-demo"
              action="http://www.jujiangmofang.cn/kitchenac/file/upload"
              :on-change="handleChange"
              :file-list="fileList"
              :on-progress="uploadprogress"
              :on-success="onSuccess"
              :on-remove="onRemove"
              name="upload_file"
            >
              <el-button size="small" type="primary">点击上传</el-button>
              <div
                slot="tip"
                class="el-upload__tip"
                v-text="progress ? '上传中,请稍后...' : '请上传备注附件。'"
              ></div>
            </el-upload>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')"
              >立即提交</el-button
            >
            <el-button @click="resetForm('ruleForm')">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <el-dialog title="信息重复" :visible.sync="dialogVisible">
      <div>
        <el-alert
          title="提示"
          type="warning"
          description="您输入的情报与其他情报信息相吻合,确定要提交吗？"
          show-icon
        >
        </el-alert>
      </div>
      <div v-for="(v, k) of repetitionList" :key="k">
        <div>
          <span>标题:</span>
          <span>{{ v.title }}</span>
        </div>
        <div>
          <span>地区:</span>
          <span v-text="`${v.provinceId}${v.cityId}${v.countyId}`"></span>
        </div>
        <div>
          <span>单位:</span>
          <span>{{ v.purchasingUnit }}</span>
        </div>
        <div>
          <span>项目联系人:</span>
          <span>{{ v.projectContact }}</span>
        </div>
        <div>
          <span>联系人电话:</span>
          <span>{{ v.projectContactPhone }}</span>
        </div>
        <div>
          <span>业务类型:</span>
          <span v-text="v == 0 ? '单人' : v == 1 ? '多人' : ''"></span>
        </div>
        <div>
          <span>预算:</span>
          <span>{{ v.budget }}</span>
        </div>
        <div>
          <span>业务所在地址:</span>
          <span>{{ v.address }}</span>
        </div>
        <div>
          <span>业务内容:</span>
          <span>{{ v.businessContent }}</span>
        </div>
      </div>
      <div>
        <el-button @click="dialogVisible = false">修改信息</el-button>
        <el-button type="primary" @click="affirm">确认提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

export default {
  
  inject: ["reload"],
  data() {
    return {
      pickerOptionsStart:{
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e6; /*今天及之前，注意数字不一样*/
        }
      },
      pickerOptionsEnd:{
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e6; /*今天及之前*/
        }
      },
      // lat1:'',
      // lat2:'',
      // lng1:'',
      // lng2:'',
      Not:true,
      //定位
      p:"",
      position:"",
      // 已提交业务信息
      Submittedbusiness: [],
      // 提示上传中显示隐藏
      progress: false,
      fileList: [],
      address:[],
      // 地区
      options: JSON.parse(sessionStorage.getItem("allAreaData")),
      // 情报查重列表显示隐藏
      dialogVisible: false,
      // 确认提交状态
      affirmSubmit: false,
      // 情报查重列表
      repetitionList: [
        {
          title: "11",
          provinceId: "12",
          cityId: "12",
          countyId: "12",
          purchasingUnit: "21",
          projectContact: "21",
          projectContactPhone: "21",
          startTrackingTime: "21",
          demandTime: "21",
          budget: "21",
          // type: "12",
          address: "21",
          businessContent: "21",
        },
      ],
      ruleForm: {
        title: "",
        region: [],
        // type: "12",
        purchasingUnit: "",
        //经纬度
        coordinate:"",
        projectContact: "",
        projectContactPhone: "",
        startTrackingTime: "",
        demandTime: "",
        budget: "",
        address: "",
        businessContent: "",
        message: [],
        purchaseType: "",
      },
      rules: {
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
        region: [{ required: false, message: "请选择区域", trigger: "change" }],
        purchasingUnit: [
          { required: true, message: "请输入采购单位", trigger: "blur" },
        ],
        projectContact: [
          { required: true, message: "请输入项目联系人", trigger: "blur" },
        ],
        projectContactPhone: [
          { required: true, message: "请输入项目联系人电话", trigger: "blur" },
        ],
        startTrackingTime: [
          {
            type: "date",
            required: true,
            message: "请选择日期",
            trigger: "change",
          },
        ],
        demandTime: [
          {
            type: "date",
            required: true,
            message: "请选择时间",
            trigger: "change",
          },
        ],
        // type: [
        //   { required: true, message: "请选择业务类型", trigger: "change" },
        // ],
        purchaseType: [
          { required: true, message: "请选择类型", trigger: "change" },
        ],
        budget: [{ required: true, message: "请输入预算", trigger: "blur" }],
        address: [
          { required: true, message: "请输入业务所在地址", trigger: "blur" },
        ],
        businessContent: [
          { required: true, message: "请输入业务内容", trigger: "blur" },
        ],
      },
      // 记录当前状态是否为重新提交
      resubmit: false,
    };
  },
  // watch: {
    
  //   "$store.state.position"(val) {
  //     sessionStorage.setItem("position", val);
  //     if (JSON.parse(sessionStorage.getItem("position")=="")) {
  //       this.position = this.position_a;
  //     }else{
  //       this.position=JSON.parse(sessionStorage.getItem("position"))
  //     }
  //   },
  // },
 
  created() {
    this.getquery();
    // console.log(this.position)
    // this.getDistance(113.659618,34.746046,116.431078,39.899564)
  },
  mounted() {
    this.ready()
    
    
  },
  methods: {
   
    qiehuan(){
      this.Not=false
      console.log(this.rules.region[0])
      this.rules.region[0].required=true
      this.ruleForm.coordinate=""
      this.p=""
      sessionStorage.setItem("position", "");
    },
     //默认ip定位
    async ready() {
      const res = await this.$http({
        method: "get",
        url: `baidu/location/ip`,
        params: {
          ak: "u4FH7X1ixpIyRmjGu6XrQh4LxvtXkAtK",
          // ip: cip,
          coor: "bd09ll",
        },
      });
      this.p=`${res.data.content.point.y+','+res.data.content.point.x}`
      this.ruleForm.coordinate=this.p
      // console.log(res.data,this.p,"经纬度","存入",this.ruleForm.coordinate)
      this.positions()
      },
    //定位
    async positions() {
      if(JSON.parse(sessionStorage.getItem("position")==""||JSON.parse(sessionStorage.getItem("position"))==null)){
        const res = await this.$http({
        method: "get",
        url: `baidu/reverse_geocoding/v3`,
        params: {
          ak: "u4FH7X1ixpIyRmjGu6XrQh4LxvtXkAtK",
          coordtype:"gcj02ll",
          output:"json",
          location:this.p,
          // location:"34.76028478438927,113.63703936593177",
        },
      
      })
      let result=res.data.result
     console.log(res.data.result)
     var a=[]
     a.push(result.addressComponent.adcode.slice(0,2))
     a.push(result.addressComponent.adcode.slice(0,4))
     a.push(result.addressComponent.adcode.slice(0,6))
     this.ruleForm.region=a
     this.position=result.addressComponent.province+result.addressComponent.city+result.addressComponent.district
      }
      else{this.position=JSON.parse(sessionStorage.getItem("position"))}
     
      
      // console.log(this.position,"位置",this.ruleForm.region)

  },
      
    // 获取路由传参
    getquery() {
      console.log(this.$route.query);
      if (this.$route.query.hasOwnProperty("item")) {
        // 当前状态为重新提交状态
        this.resubmit = true;
        this.ruleForm = JSON.parse(this.$route.query.item);
        this.ruleForm.region = [
          this.ruleForm.provinceId,
          this.ruleForm.cityId,
          this.ruleForm.countyId,
        ];
        this.ruleForm.startTrackingTime = this.ruleForm.startTrackingTime.split(
          " "
        )[0];
        this.ruleForm.demandTime = this.ruleForm.demandTime.split(" ")[0];
        delete this.ruleForm.applyNum;
        delete this.ruleForm.businessFollowList;
        delete this.ruleForm.businessUser;
        delete this.ruleForm.businessUserId;
        delete this.ruleForm.createTime;
        delete this.ruleForm.name;
        delete this.ruleForm.phone;
        delete this.ruleForm.state;
        delete this.ruleForm.updateTime;
        delete this.ruleForm.userId;
        delete this.ruleForm.userShowVo;
        delete this.ruleForm.type;
        // 如果上传的有备注附件，将备注附件添加到fileList列表
        if (JSON.parse(this.ruleForm.message).length) {
          this.fileList = JSON.parse(this.ruleForm.message);
        }

        console.log(this.ruleForm);
      }
    },
    // 查看已提交业务
    async looksetbusiness() {
      const res = await this.$http({
        method: "get",
        url: "front/businessInfo/findByUserId?sortType=create_time desc",
      });
      if (res.data.status == 200) {
        console.log(res.data.data.list);
        this.Submittedbusiness = res.data.data.list;
        // this.lookdialogTableVisible = true;
        this.$router.push({
          path: "/wallBulletinSubmit",
          query: {
            item: JSON.stringify(this.Submittedbusiness),
          },
        });
      }
    },
    // async looksetbusinessall() {
    //   const res = await this.$http({
    //     method: "get",
    //     url: "front/businessInfo/findall?sortType=create_time desc",
    //   });
    //   if (res.data.status == 200) {
    //     console.log(res.data.data.list);
    //     this.Submittedbusiness = res.data.data.list;
    //     // this.lookdialogTableVisible = true;
    //     this.$router.push({
    //       path: "/wallBulletinSubmitall",
    //       query: {
    //         item: JSON.stringify(this.Submittedbusiness),
    //       },
    //     });
    //   }
    // },
    // 文件删除时的钩子
    onRemove(file, fileList) {
      this.fileList = fileList;
      console.log(this.fileList);
    },
  //计算经纬距离
  //  getDistance(lat1, lng1, lat2,lng2) {
  //   const radLat1 = (lat1 * Math.PI) / 180.0;
  //   const radLat2 = (lat2 * Math.PI) / 180.0;
  //   const a = radLat1 - radLat2;
  //   const b = (lng1 * Math.PI) / 180.0 - (lng2 * Math.PI) / 180.0;
  //   let s =
  //       2 *
  //       Math.asin(
  //           Math.sqrt(
  //               Math.pow(Math.sin(a / 2), 2) +
  //                   Math.cos(radLat1) *
  //                       Math.cos(radLat2) *
  //                       Math.pow(Math.sin(b / 2), 2)
  //           )
  //       );
  //   s *= 6371.137; // EARTH_RADIUS;
  //   s = Math.round(s * 100) / 100;
  //   s=s*1.609
  //   console.log(s)
  //   return s; // 调用 return的距离单位为km
    
  //   },
    

    // 文件上传成功时的钩子
    onSuccess(response, file, fileList) {
      this.progress = false;
      this.fileList = fileList;
      console.log(this.fileList);
    },
    // 文件上传时的钩子
    uploadprogress(event, file, fileList) {
      this.progress = true;
    },
    handleChange(file, fileList) {
      // console.log(file);provinceId
      // console.log(fileList);
      // console.log(this.fileList);
    },
    // 确认提交情报
    affirm() {
      this.affirmSubmit = true;
      this.submitForm("ruleForm");
    },
    // 提交情报
    submitForm(formName) {
      
      console.log(this.ruleForm);
      this.$refs[formName].validate(async (valid) => {
        let from = JSON.parse(JSON.stringify(this.ruleForm));
        console.log(from,469)
        from.provinceId = from.region[0];
        from.cityId = from.region[1];
        from.countyId = from.region[2];
        from.message = [];
        this.fileList.map((v, k) => {
          from.message.push({});
          from.message[k].name = v.name;
          from.message[k].url = `${v.response.data.url}${v.response.data.uri}`;
        });
        // delete from.region;
        if (valid) {
          let size = 0;
          if (!this.affirmSubmit) {
            const res = await this.$http({
              methos: "get",
              url: `front/businessInfo/findAll?projectContact=${from.projectContact}&projectContactPhone=${from.projectContactPhone}&purchasingUnit=${from.projectContactPhone}`,
            });
            size = res.data.data.size;
            this.repetitionList = res.data.data.list;
            console.log(this.repetitionList,385)
          }
          // 如果当前为查重过状态，或者查重结果为0并且当前状态不能为重新提交则提交
          if ((this.affirmSubmit || size == 0) && !this.resubmit) {
            console.log(from);
            delete from.region;
            from.message = JSON.stringify(from.message);
            console.log(from);
            const data = await this.$http({
              method: "post",
              url: "front/businessInfo/save",
              data: {
                ...from,
              },
            });
            if (data.data.status == 200) {
              this.$message({
                message: "发布成功",
                type: "success",
              });
              this.affirmSubmit = false;
              this.reload();
            } // 如果当前为查重过状态，或者查重结果为0并且当前状态为重新提交则重新提交
          } else if ((this.affirmSubmit || size == 0) && this.resubmit) {
            // 重新提交
            console.log(from);
            console.log(this.resubmit, "重新提交");
            delete from.region;
            from.message = JSON.stringify(from.message);
            console.log(from);
            const data = await this.$http({
              method: "post",
              url: "front/businessInfo/resubmit",
              data: {
                ...from,
              },
            });
            if (data.data.status == 200) {
              this.$message({
                message: "重新提交成功",
                type: "success",
              });
              this.affirmSubmit = false;
              this.$router.push({ query: {} });
              console.log(1111);
              window.location.reload();
            }
          } else {
            this.dialogVisible = true;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 地区选择change
    regionhandleChange(value) {
      // 显示lable
      // console.log(this.$refs["cascaderAddr"].getCheckedNodes()[0].pathLabels);
      this.address=this.$refs["cascaderAddr"].getCheckedNodes()[0].pathLabels[0]+this.$refs["cascaderAddr"].getCheckedNodes()[0].pathLabels[1]+this.$refs["cascaderAddr"].getCheckedNodes()[0].pathLabels[2]
      // console.log(this.address);
      // this.
      sessionStorage.setItem("position", JSON.stringify(this.address));
      
      this.Manual()
    },
    async Manual(){
      const res = await this.$http({
        method: "get",
        url: `baidu/geocoding/v3`,
        params: {
          ak: "u4FH7X1ixpIyRmjGu6XrQh4LxvtXkAtK",
          address:this.address,
          output:"json"
        },
      });
      this.p=`${res.data.result.location.lat+','+res.data.result.location.lng}`
      this.ruleForm.coordinate=this.p
      // console.log(res.data.result.location,570)
    }
    
  },
};
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  margin-top: 150px;
  & > div:nth-child(1) {
    background: #f5f5f6;
    height: 150px;
    margin: 0 auto;
    font-size: 36px;
    color: #101d37;
    font-weight: 700;
    text-align: center;
    line-height: 120px;
    position: relative;
    color: #101d37 !important;
    & > .looksetbusiness {
      position: absolute;
      right: 150px;
      top: 50px;
      height: 30px;
      color: #5079d9;
      line-height: 0px;
      cursor: pointer;
      font-size: 18px;
      font-weight: 700;
      
    }
    // & > .looksetbusinessall {
    //   position: absolute;
    //   right: 150px;
    //   top: 90px;
    //   height: 30px;
    //   color: #5079d9;
    //   line-height: 0px;
    //   cursor: pointer;
    //   font-size: 18px;
    //   font-weight: 700;
    // }
  }
  .box-form {
    padding: 20px;
    width: 70%;
    margin: 0 auto;
  }
  & ::v-deep .el-progress__text {
    display: none;
  }
  .position{
    height: 18px;
    margin-bottom: 5px;
  font-size: 16px;
  color: #505050;
}
.qiehuan{
  font-size: 12px;
  font-family: Arial;
  height: 15px;
  
}
}

</style>